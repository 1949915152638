/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import Button from '@atlaskit/button';
import RefreshIcon from '@atlaskit/icon/core/migration/refresh';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export interface Props {
	issueCount: number;
	isCurrentDataFiltered: boolean;
	isLoadingIssues: boolean;
	onFilterReloadClick: () => void;
}

export const IssueSearchCount = (props: Props) => {
	const { formatMessage } = useIntl();
	const { issueCount, isCurrentDataFiltered, isLoadingIssues, onFilterReloadClick } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleReloadClick = () => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'jsmQueueFiltering reloaded');
		onFilterReloadClick();
	};

	const skeleton = (
		<Skeleton
			height="24px"
			width="100px"
			data-testid="servicedesk-queues-agent-view.layout.queues-details.issue-search-count.skeleton"
		/>
	);

	return (
		<Box
			xcss={
				fg('jsm_views_inside_queues_-_main_flag')
					? [
							wrapperBaseStyles,
							fg('corcap-2944-add-jqlbuilder-to-jsm-queues') && negativeMarginStyles,
							!fg('corcap-2944-add-jqlbuilder-to-jsm-queues') && wrapperPaddingstyles,
						]
					: [wrapperOldStyles]
			}
		>
			<Box role="region" xcss={layoutStyle}>
				<span aria-live="polite">
					{!isCurrentDataFiltered &&
						issueCount !== 0 &&
						!isLoadingIssues &&
						formatMessage(
							fg('itsm-issue-renaming')
								? messages.numberOfIssuesIssueTermRefresh
								: messages.numberOfIssues,
							{ issueCount },
						)}
					{isCurrentDataFiltered &&
						!isLoadingIssues &&
						formatMessage(
							fg('itsm-issue-renaming')
								? messages.numberOfFilteredIssuesIssueTermRefresh
								: messages.numberOfFilteredIssues,
							{
								filteredIssueCount: issueCount,
							},
						)}
				</span>

				{isCurrentDataFiltered && !isLoadingIssues && (
					<>
						<Box xcss={spacerStyles} />
						<Tooltip position="bottom" content={formatMessage(messages.refreshResults)}>
							<Button
								aria-label={formatMessage(messages.refreshResults)}
								iconBefore={
									<RefreshIcon label={formatMessage(messages.refreshResults)} LEGACY_size="small" />
								}
								data-testId="servicedesk-queues-agent-view.layout.queues-details.issue-count.reload-button"
								appearance="subtle"
								spacing="compact"
								onClick={handleReloadClick}
							/>
						</Tooltip>
					</>
				)}

				{isLoadingIssues && fg('add_ufo_load_hold_to_custom_queue') && (
					<UFOLoadHold name="issue-count">{skeleton}</UFOLoadHold>
				)}

				{isLoadingIssues && skeleton}
			</Box>
		</Box>
	);
};

export default IssueSearchCount;

const layoutStyle = xcss({
	display: 'flex',
	alignItems: 'center',
});

const spacerStyles = xcss({
	width: 'space.100',
});

const wrapperBaseStyles = xcss({
	paddingBlockEnd: 'space.150',
	color: 'color.text.subtle',
	paddingBlockStart: 'space.0',
});

const wrapperPaddingstyles = xcss({
	paddingBlockStart: 'space.150',
});

const wrapperOldStyles = xcss({
	color: 'color.text.subtle',
	marginTop: 'space.150',
	marginBottom: 'space.300',
});

const negativeMarginStyles = xcss({
	marginTop: 'space.negative.050',
});
