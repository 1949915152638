// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { withTheme } from '../../../../../app/context/theme-context/index.tsx';
import type { CompiledTheme } from '../../../../../model/themes/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{
	height: string | number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	addBarOverflow: any;
	theme: CompiledTheme;
}>({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ theme, addBarOverflow }) => `-${addBarOverflow + theme.row.borderWidth}px`,
	marginBottom: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FakeContent = styled.div<{ width: string | number; height: string | number }>({
	flex: '0 0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
});

type Props = {
	rowsHeight: number;
	contentHeight: number;
	contentWidth: number;
	isAddLinkShown: boolean;
	addBarOverflow: number;
	AdditionalColumnsContainer: ComponentType<{
		children: ReactNode;
		placeholderKey?: string;
	}>;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
class ScrollArea extends PureComponent<Props> {
	getAreaHeight(): number {
		const { rowsHeight, contentHeight, isAddLinkShown, theme } = this.props;
		// we are reducing Scrollbar height in src/common/components/virtual-table/view/table/content/scrollbars/vertical
		// /index-dumb.js to accommodate stuff below table within the viewport.
		// Utility Row takes whatever height is left below rowList on the viewport hence need to reduce it here as well
		// else it is introducing vertical scroll even if we do not have enough rows to scroll.
		const spareContentSpace =
			contentHeight - rowsHeight - (theme.scrollbar.offset - theme.scrollbar.paddingTop);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { defaultHeight } = (theme as CompiledTheme).row;
		const addLinkSpace = isAddLinkShown ? defaultHeight : 0;

		return spareContentSpace > 0 ? spareContentSpace : addLinkSpace;
	}

	render() {
		const { contentWidth, AdditionalColumnsContainer, addBarOverflow, theme } = this.props;

		const areaHeight = this.getAreaHeight();

		// there is either
		// 1) no space required to be filled, or the add link is not visible
		// 2) no visible additional rows/details panel is open (as derived by no content width)
		if (areaHeight === 0 || contentWidth === 0) {
			return null;
		}

		return (
			<Wrapper height={areaHeight} addBarOverflow={addBarOverflow} theme={theme}>
				{fg('further_improve_vc90_performence_of_queues_page') ? (
					<AdditionalColumnsContainer placeholderKey="utility-row.scroll-area">
						<FakeContent width={contentWidth} height={areaHeight} />
					</AdditionalColumnsContainer>
				) : (
					<AdditionalColumnsContainer>
						<FakeContent width={contentWidth} height={areaHeight} />
					</AdditionalColumnsContainer>
				)}
			</Wrapper>
		);
	}
}

export default withTheme(ScrollArea);
