/** @jsx jsx */
import React from 'react';
import { css, styled, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	width: number;
	isVisible: boolean;
	shouldUseCssForRendering: boolean;
};

export default function ResizeColumnOverlay(props: Props) {
	const { width, isVisible, shouldUseCssForRendering } = props;

	if (fg('further_improve_vc90_performence_of_queues_page')) {
		return (
			<div
				data-testId="virtual-table.table.main.resizeColumnOverlay"
				css={[styles, isVisible ? visibleStyles : invisibleStyles]}
				style={{
					width: shouldUseCssForRendering ? '100%' : `${width}px`,
				}}
			/>
		);
	}

	return <Overlay width={width} isVisible={isVisible} />;
}

const styles = css({
	pointerEvents: 'none',
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	backgroundColor: token('elevation.surface.overlay'),
});

const visibleStyles = css({
	opacity: 0.5,
	transition: 'opacity 0.25s ease-in',
});

const invisibleStyles = css({
	opacity: 0,
	transition: 'opacity 0s ease-in',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Overlay = styled.div<{
	width: string | number;
	isVisible: boolean;
}>({
	pointerEvents: 'none',
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	backgroundColor: token('elevation.surface.overlay'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isVisible }) => (isVisible ? '0.5' : '0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transition: ({ isVisible }) => `opacity ${isVisible ? '0.25s' : '0s'} ease-in`,
});
