import type { Dispatch } from 'redux';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { LOAD_ISSUES_ACTION_SOURCE_FILTER } from '../../../../model/index.tsx';
import { isNewFilterQueryPendingAction } from '../../../../state/actions/filter/index.tsx';
import { loadIssuesAction } from '../../../../state/actions/issue/index.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import { getIsCurrentDataFiltered } from '../../../../state/selectors/filter/index.tsx';
import { getIssueCount } from '../../../../state/selectors/issues/index.tsx';
import { isLoadingIssues } from '../../../../state/selectors/ui/index.tsx';
import IssueSearchCount from './view.tsx';

const mapDispatchToProps = (
	dispatch: Dispatch<{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		type: any;
	}>,
) => ({
	onFilterReloadClick: () => {
		dispatch(isNewFilterQueryPendingAction(true));
		dispatch(loadIssuesAction(0, LOAD_ISSUES_ACTION_SOURCE_FILTER));
	},
});

const mapStateToProps = (state: State) => ({
	issueCount: getIssueCount(state),
	isCurrentDataFiltered: getIsCurrentDataFiltered(state),
	isLoadingIssues: isLoadingIssues(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueSearchCount);
